<mat-card-header >
  <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign.gt-sm=" center" class="w-100">
    <div fxFlex="100" fxFlex.gt-sm>
      <h6 class="mb-0 ">{{'APPOINTMENTS' | translate: {Default: 'Appointments'} }}</h6>
    </div>
    <div fxFlex="auto" fxHide fxShow.gt-sm></div>
    <div fxFlex="100" fxFlex.gt-sm="nogrow">
      <mat-slide-toggle class="mr-1" [labelPosition]="'before'" color="accent" [checked]="showOnlyCancelled"
        [(ngModel)]="showOnlyCancelled" (ngModelChange)="cancelledFilterChanged($event)">
        {{'ONLY_SHOW_CANCELLED' | translate: {Default: 'Only Show Cancelled'} }}
      </mat-slide-toggle>

      <button *ngIf="addAppointmentAllowed" [hidden]="isViewMode" mat-button color="accent" (click)="addAppointment()">
        <mat-icon class="vm">event</mat-icon> <span class="vm">{{'CREATE_APPT' | translate: {Default: 'Create
          Appointment'} }}</span>
      </button>
      <button *ngIf="addAppointmentAllowed && selectedAppointments?.length" mat-button color="accent" (click)="deleteAppointments()">
        <mat-icon class="vm">delete</mat-icon> <span class="vm">{{'DELETE_SELECTED' | translate: {Default: 'Delete
          Selected'} }}</span>
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="sendAppointments()">{{'SEND_ALL_APPTS' | translate: {Default: 'Send All
          Appointments'} }}</button>
      </mat-menu>
      <button *ngIf="addAppointmentAllowed" mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<!-- {{historyList | json}} -->
<div fxLayout="row" fxLayoutAlign="center center" class="mdc-p-3">
  <mat-button-toggle-group [(ngModel)]="historyMode" (ngModelChange)="historyModeChanged($event)" name="historyMode"
    aria-label="History Mode" class="button-group-payments grouplarge">
    <mat-button-toggle value="past">{{'PAST_APPTS' | translate: {Default: 'Past Appointments'} }}
      ({{pastAppointments.length}})</mat-button-toggle>
    <mat-button-toggle value="future">{{'UPCOMING_APPTS' | translate: {Default: 'Upcoming Appointments'} }}
      ({{futureAppointments.length}})</mat-button-toggle>
    <mat-button-toggle *ngIf="isVaccinationFeatureAllowed" value="vaccinations">{{'VACCINATIONS' | translate: {Default:
      'Vaccinations'} }} ({{vaccinations.length}})</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div *ngIf="historyMode === 'past'" class="mdc-mb-2">
  <ul class="historylist mdc-m-0" *ngIf="pastAppointments.length">
    <li *ngFor="let pastAppointment of pastAppointments">
      <div fxLayout="row">
        <div fxFlex="nogrow">
         <mat-checkbox [hidden]="isViewMode" *ngIf="!showOnlyCancelled" class="mx-2 vm" (change)="selectAppointment($event, pastAppointment.appointmentId)"></mat-checkbox>
        </div>
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="2%">
          <div fxFlex="grow" fxFlex.gt-sm="20" (click)="openViewAppointmentModal(pastAppointment)">
            <p class="my-1 view-invoice">{{pastAppointment.label}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18" (click)="openViewAppointmentModal(pastAppointment)">
            <p class="my-1 view-invoice mat-text-secondary">{{pastAppointment.services}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="15">
            <p class="my-1 mat-text-secondary">{{pastAppointment.blades}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18">
            <p class="my-1 mat-text-secondary">{{pastAppointment.notes}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18">
            <p class="my-1 mat-text-secondary">{{pastAppointment.cancellationReason}}</p>
          </div>
        </div>

        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3 d-inline-block mx-2" matTooltip="Edit Appointment"
            (click)="openEditAppointmentModal(pastAppointment)" *ngIf="!isViewMode">
            <mat-icon class="view-invoice vm">
              edit_calendar
            </mat-icon>
          </div>
          <div class="avatar avatar-50 circle border-3 d-inline-block" *ngIf="pastAppointment.invoiceId"
            matTooltip="View Invoice" (click)="viewInvoice(pastAppointment)">
            <mat-icon class="view-invoice vm">
              receipt
            </mat-icon>
          </div>
          <!-- <p class="mb-0" (click)="viewInvoice(pastAppointment)">Invoice</p> -->
        </div>
      </div>
    </li>
  </ul>
  <div class="no-history" *ngIf="!pastAppointments.length">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">timeline</mat-icon>
      </div>
      <h6>{{'NO_PAST_APPTS' | translate: {Default: 'There are no past appointments'} }}</h6>
    </div>
  </div>
</div>

<div *ngIf="historyMode === 'future'" class="mdc-mb-2">
  <ul class="historylist  mdc-m-0" *ngIf="futureAppointments.length">
    <li *ngFor="let futureAppointment of futureAppointments">
      <div fxLayout="row" fxLayoutAlign=" start">
        <div fxFlex="nogrow">
         <mat-checkbox [hidden]="isViewMode" *ngIf="!showOnlyCancelled" class="mx-2 vm" (change)="selectAppointment($event, futureAppointment.appointmentId)"></mat-checkbox>
          <!-- <p class="mb-0" (click)="viewInvoice(futureAppointment)">Invoice</p> -->
        </div>
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="2%">
          <div fxFlex="grow" fxFlex.gt-sm="20" (click)="openViewAppointmentModal(futureAppointment)">
            <p class="my-1 view-invoice">{{futureAppointment.label}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18" (click)="openViewAppointmentModal(futureAppointment)">
            <p class="my-1 mat-text-secondary view-invoice">{{futureAppointment.services}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="15">
            <p class="my-1 mat-text-secondary">{{futureAppointment.blades}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18">
            <p class="my-1 mat-text-secondary">{{futureAppointment.notes}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18">
            <p class="my-1 mat-text-secondary">{{futureAppointment.cancellationReason}}</p>
          </div>
        </div>
        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3 d-inline-block mx-2" matTooltip="Edit Appointment"
            (click)="openEditAppointmentModal(futureAppointment)" *ngIf="!isViewMode">
            <mat-icon class="view-invoice vm">
              edit_calendar
            </mat-icon>
          </div>
          <div class="avatar avatar-50 circle border-3 d-inline-block" *ngIf="futureAppointment.invoiceId"
            matTooltip="View Invoice" (click)="viewInvoice(futureAppointment)">
            <mat-icon class="view-invoice vm">
              receipt
            </mat-icon>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div class="no-history " *ngIf="!futureAppointments.length">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">event</mat-icon>
      </div>
      <h6>{{'NO_UPCOMING_APPTS' | translate: {Default: 'There are no upcoming appointments'} }}</h6>
    </div>
  </div>
</div>

<div *ngIf="historyMode === 'vaccinations' && isVaccinationFeatureAllowed" class="mdc-mb-2">
  <app-view-pet-vaccinations [contextIdDto]="contextIdDto" [vaccinations]="vaccinations" [pet]="pet"
    (loadVaccinations)="loadVaccinations()"></app-view-pet-vaccinations>
</div>